// import React, { useEffect, useState } from 'react';

const LogoIcon = ({width=24, height=34}) => {
  return (
    <div onClick={() => window.location.href = '/'} style={{ cursor: 'pointer', display: 'inline-block' }}>
      <svg
        style={{ margin: '0.5rem' }}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="80.9 22.1 54.2 72.1"
        width={width}
        height={height}
      >
        <path
          fill="#fffffe"
          d="M135.1 40.1v-21H80.9v21.1h12v36.1h-12v21.1h54.2V76.2h-12V40.1h12zM123 79.2h9v15H83.9v-15h9c1.7 0 3-1.3 3-3V40.1c0-1.7-1.3-3-3-3h-9v-15H132v15h-9c-1.7 0-3 1.3-3 3v36.1c0 1.7 1.4 3 3 3z"
        />
        <path
          fill="#e84b37"
          d="M123 37.1h9v-15H83.9v15h9c1.7 0 3 1.3 3 3v36.1c0 1.7-1.3 3-3 3h-9v15H132v-15h-9c-1.7 0-3-1.3-3-3V40.1c0-1.6 1.4-3 3-3z"
        />
      </svg>
    </div>
  );
};

export default LogoIcon;
