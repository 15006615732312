import Sidebar from './Sidebar';
import MainContent from './MainContent';

export default function Component() {
  return (
    <div className="flex h-screen bg-black text-white" style={{maxHeight: '-webkit-fill-available', overflow: 'hidden'}}>
      <Sidebar />
      <MainContent />
    </div>
  );
}
