import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './components/Home';
import Login from './components/Login';
// import FormSubmit from './components/FormSubmit';
import Profile from './components/Profile';
import FullPageLoad from './components/FullPageLoad';
// import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Optional: For handling loading states

  useEffect(() => {
    // Fetch user info on app load
    const fetchUserInfo = async () => {
      try {
        const response = await fetch('/auth/me', { credentials: 'include' });
        if (response.ok) {
          const data = await response.json();
          setUser(data.user);
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
        setIsAuthenticated(false);
      } finally {
        // setTimeout(() => setLoading(false), 10000);
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  if (loading) return <FullPageLoad />;

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? <Home user={user} /> : <Navigate to="/login" />} />
        {/*<Route path="/" element={<Home user={{netid: 'yatzkan3'}} />} />*/}
        <Route path="/me" element={isAuthenticated ? <Profile user={user} /> : <Navigate to="/login" />} />
        {/*<Route path="/thank-you" element={<FormSubmit />} />*/}
        <Route path="/login" element={<Login />} />
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
