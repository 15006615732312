import LogoIcon from './LogoIcon';

export default function Sidebar() {
  return (
    <div className="hidden lg:block w-64 w-48 p-6 flex flex-col justify-between">
      <div>
        <div className="mb-8">
          <LogoIcon />
        </div>
        <nav className="space-y-2 w-full">
          <a className="block text-sm text-gray-400 hover:text-[#BB5E0E] w-full truncate" href="#">discover</a>
        </nav>
        <div className="mt-8 text-sm text-gray-400 w-full">
          <p className="truncate">welcome to oskee,</p> 
          <p className="truncate">a place to find</p>
          <p className="truncate">people doing</p>
          <p className="truncate">cool stuff</p>
        </div>
        <div className="mt-4 text-sm text-gray-400 w-full">
          <p className="truncate">this is the place where</p>
          <p className="truncate">people work on ideas</p>
          <p className="truncate">they are passionate</p>
          <p className="truncate">about.</p>
        </div>
        <div className="mt-4 text-sm text-gray-400 w-full"><p className="truncate">give it a try.</p></div>
        <div className="mt-4 text-sm text-gray-400 w-full"><p className="truncate">see you in a search</p></div>
        <div className="text-sm text-gray-400 w-full"><p className="truncate">soon</p></div>
      </div>
      <div className="space-y-2 w-full">
        <a className="block text-sm text-gray-400 hover:text-[#BB5E0E] w-full truncate text-left" href="#">profile</a>
      </div>
    </div>
  )
}


