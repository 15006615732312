import { useState } from 'react';
import UserCarousel from './UserCarousel';
import PromptCarousel from './PromptCarousel';
import { MainChatArea } from './MainChatArea';
import ChatInput from './ChatInput';

export default function MainContent() {
  const [chatStarted, setChatStarted] = useState(false);
  const [messages, setMessages] = useState([]);

  const handleUserMessage = (message) => {
    if (!chatStarted) setChatStarted(true);

    let isLoading = !!document.querySelector('.flex.items-center.space-x-2.mt-2');
    if (isLoading) return false; // todo: keep message input if not sending

    // Add user's message
    setMessages((prev) => [...prev, { sender: 'you', text: message }]);

    // Add empty bot message with messageChunks to show loading indicator immediately
    setMessages((prev) => [
      ...prev,
      { sender: 'oskee', messageChunks: [], loading: true },
    ]);

    // Fetch bot response
    fetchBotResponse(message);
  };

  const fetchBotResponse = async (userMessage) => {
    try {
      let chatHistory = [...document.querySelector('.chat-container')?.children || []].map(x => {
          let m = x.firstChild;
          let obj = {
              from: m.children[0].innerText == 'you' ? 'user' : 'oskee',
              content: [...m.children[1].querySelectorAll('p')].filter(p => p.parentElement == m.children[1]).map(p => p.textContent).join(''),
          }
          if (m.children[1].querySelector('div')?.children) obj.people_suggested = [...m.children[1].querySelector('div')?.children].map(d => d.querySelector('h3').textContent);
          return obj;
      });
      const response = await fetch('/api/query', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ message: userMessage, chatHistory }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();
      let done = false;

      while (!done) {
        const { value, done: readerDone } = await reader.read();
        done = readerDone;

        if (value) {
          const chunk = decoder.decode(value, { stream: true }).trim();
          const lines = chunk.split('\n').filter(line => line.startsWith('data: '));

          for (const line of lines) {
            const data = line.replace('data: ', '').trim();
            if (data === '[DONE]') {
              done = true;
              // Update loading state to false
              setMessages((prev) => {
                const lastMessage = prev[prev.length - 1];
                if (lastMessage && lastMessage.sender === 'oskee') {
                  return [
                    ...prev.slice(0, -1),
                    { ...lastMessage, loading: false, showCreators: true },
                  ];
                }
                return prev;
              });
            } else {
              const parsedContent = JSON.parse(data);

              let creators = parsedContent?.people;

              // Add new chunk to the message incrementally
              setMessages((prev) => {
                const lastMessage = prev[prev.length - 1];

                if (lastMessage && lastMessage.sender === 'oskee') {
                  const updatedChunks = parsedContent.response ? [...lastMessage.messageChunks, parsedContent.response] : lastMessage.messageChunks;
                  let obj = { ...lastMessage, messageChunks: updatedChunks, loading: !done };
                  if (creators) obj.creators = creators;

                  return [
                    ...prev.slice(0, -1),
                    obj,
                  ];
                } else {
                  return prev;
                }
              });
            }
          }
        }
      }
    } catch (error) {
      console.error('Error fetching bot response:', error);

      setMessages((prev) => {
        const lastMessage = prev[prev.length - 1];
        if (lastMessage && lastMessage.sender === 'oskee') {
          return [
            ...prev.slice(0, -1),
            { ...lastMessage, messageChunks: ['Error fetching response. Please try again.'], loading: false },
          ];
        } else {
          return prev;
        }
      });
    }
  };

  const handlePromptClick = (prompt) => {
    handleUserMessage(prompt);
  };

  return (
    <div className="flex flex-col flex-1 p-6 overflow-hidden relative h-full pb-0">
      {!chatStarted ? (
        <>
          <div className="flex-1 overflow-auto space-y-1">
            <h1 className="text-6xl font-bold mb-8 text-center">
              find and
              <br />
              <span className="text-[#BB5E0E]">be found.</span>
            </h1>
            <UserCarousel />
            <PromptCarousel reverse={true} onPromptClick={handlePromptClick} />
            <PromptCarousel reverse={false} onPromptClick={handlePromptClick} />
          </div>
          <ChatInput onSubmit={handleUserMessage} />
        </>
      ) : (
        <MainChatArea messages={messages} onSendMessage={handleUserMessage} />
      )}
    </div>
  );
}  
