import { Input } from "@/components/ui/input";
import { Button } from "@/components/ui/button";
import { Share2 } from "lucide-react";
import { ChatMessage } from "./ChatMessage";
import { CreatorCard } from "./CreatorCard";
import { motion, AnimatePresence } from "framer-motion";
import ChatInput from './ChatInput';

export function MainChatArea({ messages, onSendMessage }) {
  return (
    <div className="flex-1 flex flex-col bg-black text-white h-full">
      <header className="flex justify-between items-center p-4 border-b border-gray-800">
        <h1 className="text-xl font-bold">Chat</h1>
        {/*<Button
          variant="ghost"
          size="sm"
          className="text-gray-400 hover:text-white"
        >
          <Share2 className="mr-2 h-4 w-4" />
          share search
        </Button>*/}
      </header>

      <div className="chat-container flex-grow overflow-auto p-4 space-y-4">
        <AnimatePresence>
          {messages.map((msg, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
            >
              <ChatMessage
                sender={msg.sender}
                text={msg.text}
                messageChunks={msg.messageChunks}
                creators={msg.creators}
                showCreators={msg.showCreators}
              />
            </motion.div>
          ))}
        </AnimatePresence>
      </div>

      <ChatInput onSubmit={onSendMessage} />
    </div>
  );
}
