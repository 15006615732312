import { motion, AnimatePresence } from "framer-motion";
import { CreatorCard } from "./CreatorCard";

export function ChatMessage({ sender, text, messageChunks = [], showCreators, creators }) {
  return (
    <div className="bg-black p-3 border border-gray-800 rounded-lg shadow-md">
      <p className="font-semibold text-sm text-[#BB5E0F]">{sender}</p>

      {sender === 'oskee' && !messageChunks.length && <LoadingIndicator />}

      <div className="text-gray-300 mt-2">
        {/* Existing text (for user messages or any pre-existing text) */}
        {text && text.split("\n").map((paragraph, index) => (
          <p key={`text-${index}`} className="mb-1">
            {paragraph}
          </p>
        ))}

        {/* Message chunks (for bot messages) */}
        {sender === 'oskee' && (
          <AnimatePresence>
            {messageChunks.map((chunk, index) => (
              <motion.p
                key={`chunk-${index}`}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.3 }}
                className="mb-1 inline"
              >
              {typeof chunk === 'string' && (chunk.charCodeAt() === 10 ? <p className="my-4"></p> : chunk.toLowerCase())}
              </motion.p>
            ))}

              {showCreators && creators.length > 0 && (
                <>
                  {/*<div className="grid grid-cols-2 gap-4 mt-2">*/}
                  <div className="mt-4">
                    {creators.map((user, idx) => (
                      <CreatorCard
                        key={idx}
                        name={`${user.full_name.toLowerCase()}`}
                        description={'tap to reach out'}
                        avatarSrc={user.pfp}
                        phone={user?.contact_info?.phone_number}
                        schoolEmail={user?.contact_info?.school_email}
                        personalEmail={user?.contact_info?.personal_email}
                        instagram={user?.contact_info?.instagram}
                        twitter={user?.contact_info?.twitter}
                        telegram={user?.contact_info?.telegram}
                        website={user?.contact_info?.website}
                        linkedin={user?.contact_info?.linkedin}
                      />
                    ))}
                  </div>
                </>
                )
            }

          </AnimatePresence>
        )}
      </div>
    </div>
  );
}

function LoadingIndicator() {
  return (
    <div className="flex items-center space-x-2 mt-2">
      <div className="w-4 h-4 rounded-full bg-gray-400 animate-pulse"></div>
      <div className="w-4 h-4 rounded-full bg-gray-400 animate-pulse delay-75"></div>
      <div className="w-4 h-4 rounded-full bg-gray-400 animate-pulse delay-150"></div>
    </div>
  );
}
