import React, { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Send } from 'lucide-react';

export default function ChatInput({ onSubmit }) {
  const [message, setMessage] = useState('');

  const handleSubmit = () => {
    if (message.trim()) {
      onSubmit(message);
      setMessage('');
    }
  };

  return (
    <div className="p-4 bg-black border-t border-gray-700 relative">
      <Input
        placeholder="message oskee..."
        className="w-full bg-black border-gray-700 text-white placeholder-gray-500 pr-10"
        value={message}
        onChange={(e) => setMessage(e.target.value)}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            handleSubmit();
          }
        }}
      />
      <button
        className="absolute right-6 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-white transition-colors"
        onClick={handleSubmit}
        aria-label="Send message"
      >
        <Send size={20} />
      </button>
    </div>
  );
}