import { useRef, useEffect } from 'react';

const names = ["austin", "advay", "monisa", "andrew", "sammy", "cristian"];

export default function UserCarousel() {
  const userCarouselRef = useRef(null);

  useEffect(() => {
    const userScroll = userCarouselRef.current;
    let animationId;

    const scrollUser = () => {
      if (userScroll.scrollLeft >= userScroll.scrollWidth / 2) {
        userScroll.scrollLeft = 0;
      } else {
        userScroll.scrollLeft += 1;
      }
      animationId = requestAnimationFrame(scrollUser);
    };

    animationId = requestAnimationFrame(scrollUser);

    return () => cancelAnimationFrame(animationId);
  }, []);

  return (
    <div ref={userCarouselRef} className="flex overflow-x-hidden space-x-4 pb-4 mb-8" aria-label="User profiles carousel">
      {[...Array(2)].map((_, i) => (
        <div key={i} className="flex space-x-4">
          {names.map((name) => (
            <div key={`${name}-${i}`} className="flex-none w-64 grayscale hover:grayscale-0 transition duration-300 ease-in-out">
              <div className="bg-[#0A0A0A] p-4 rounded-lg">
                <img className="aspect-video bg-[#111111] mb-2 rounded object-cover" src={`assets/${name}.jpg`} />
                <h3 className="text-lg font-semibold text-white">{name}</h3>
                {name == "advay" && <p className="text-sm text-gray-400">developing an email automation platform, founder of Pathlit</p>}
                {name == "austin" && <p className="text-sm text-gray-400">creating short-form content for college entrepreneurs</p>}
                {name == "monisa" && <p className="text-sm text-gray-400">ceo of a ride-sharing startup Cruizin, content creator</p>}
                {name == "andrew" && <p className="text-sm text-gray-400">founding developer of Oskee, working on coding projects</p>}
                {name == "sammy" && <p className="text-sm text-gray-400">life-style content creator, traveling the world every week</p>}
                {name == "cristian" && <p className="text-sm text-gray-400">tiktok creator documenting college experience, reality tv</p>}
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );
}
