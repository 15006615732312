import React, { useEffect, useState } from 'react';

const Profile = () => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams.has('verified')) {
      setShowToast(true);
      setTimeout(() => setShowToast(false), 3000);
    }
  }, []);

  return (
    <div className="min-h-screen bg-black text-white">
      <div className="container mx-auto px-4 py-8">
        {/* Header */}
        <header className="flex justify-between items-center mb-8 border-b border-[#E84A27] pb-2">
          <svg
            style={{ margin: '0.5rem' }}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="80.9 22.1 54.2 72.1"
            width="24"
            height="34"
          >
            <path
              fill="#fffffe"
              d="M135.1 40.1v-21H80.9v21.1h12v36.1h-12v21.1h54.2V76.2h-12V40.1h12zM123 79.2h9v15H83.9v-15h9c1.7 0 3-1.3 3-3V40.1c0-1.7-1.3-3-3-3h-9v-15H132v15h-9c-1.7 0-3 1.3-3 3v36.1c0 1.7 1.4 3 3 3z"
            />
            <path
              fill="#e84b37"
              d="M123 37.1h9v-15H83.9v15h9c1.7 0 3 1.3 3 3v36.1c0 1.7-1.3 3-3 3h-9v15H132v-15h-9c-1.7 0-3-1.3-3-3V40.1c0-1.6 1.4-3 3-3z"
            />
          </svg>

          <nav>
            <a href="/" className="text-white hover:text-gray-300 transition-colors">
              home
            </a>
          </nav>
        </header>

        {/* Main Content */}
        <main className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="md:col-span-2 space-y-8">
            <div className="flex items-center space-x-4">
              <img
                src="https://thispersondoesnotexist.com/"
                alt="Profile picture"
                className="w-24 h-24 rounded-full"
              />
              <div>
                <h1 className="text-4xl font-bold">john doe</h1>
                <p className="text-gray-400">champaign, il</p>
              </div>
            </div>

            <div>
              <h2 className="text-xl font-semibold mb-2">tldr:</h2>
              <p className="text-gray-300">
                helping students bring their ideas to life at buildillinois.
              </p>
            </div>

            <div>
              <h2 className="text-xl font-semibold mb-2">today</h2>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>student at uiuc, studying computer science</li>
                <li>working on innovative projects with fellow students</li>
              </ul>
            </div>

            <div>
              <h2 className="text-xl font-semibold mb-2">past projects</h2>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>developed a mobile app for campus events</li>
                <li>created a peer-to-peer tutoring platform</li>
              </ul>
            </div>

            <div>
              <h2 className="text-xl font-semibold mb-2">lifelong dreams</h2>
              <ul className="list-disc list-inside text-gray-300 space-y-2">
                <li>founding a tech startup that impacts millions</li>
                <li>contributing to open-source projects that advance technology</li>
              </ul>
            </div>
          </div>

          {/* Sidebar Buttons */}
          <div className="space-y-4">
            <button className="w-full bg-[#13294B] text-white py-2 px-4 rounded hover:bg-[#1F4B8D] transition-colors">
              edit
            </button>
            <button className="w-full bg-gray-800 text-white py-2 px-4 rounded hover:bg-gray-700 transition-colors">
              share
            </button>
          </div>
        </main>
      </div>

      {/* Toast Notification */}
      {showToast && (
        <div className="fixed bottom-4 right-4 bg-green-500 text-white px-4 py-2 rounded shadow-lg">
          account verified successfully!
        </div>
      )}

      {/* Footer */}
      <footer className="mt-12 p-4 text-center text-gray-400">
        proof of concept x buildillinois
      </footer>
    </div>
  );
};

export default Profile;
