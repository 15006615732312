import { useState } from 'react';
import UserCarousel from './UserCarousel';
import PromptCarousel from './PromptCarousel';
import { MainChatArea } from './MainChatArea';

import { Button } from "@/components/ui/button"
import { UserPlus, Users, UserCheck, BookOpen, Fingerprint } from 'lucide-react'


export default function Component() {
  return (
    <div className="flex h-screen bg-black text-white" style={{maxHeight: '-webkit-fill-available', overflow: 'hidden'}}>
      <div className="flex flex-col flex-1 p-6 overflow-hidden relative h-full">
        {/* Content Container */}
        <div className="overflow-auto">
          <h1 className="text-5xl font-bold mb-8 text-center">
            find and
            <br />
            <span className="text-[#BB5E0E]">be found.</span>
          </h1>
          <UserCarousel />
          <PromptCarousel reverse={true} onPromptClick={() => {}} />
          <PromptCarousel reverse={false} onPromptClick={() => {}} />
        </div>

        {/* Button Container */}
        <div style={{marginTop: '-1rem'}} className="flex-1 flex items-center justify-center">
          <Button onClick={() => window.location.href = 'https://authentication.web.illinois.edu'} className="w-min bg-[#AF6327] text-white hover:bg-[#9F6327] transition-all duration-300 transform hover:scale-105">
            <Fingerprint className="mr-2 h-4 w-4" />
            Create or Access Your Profile
          </Button>
        </div>
      </div>
    </div>
  );
}

{/*      <Button 
        className="w-full h-16 mb-8 bg-zinc-800 text-white hover:bg-zinc-700 transition-all duration-300"
      >
        <Users className="mr-2 h-4 w-4" />
          {"Create or Access Your Profile"}
      </Button>*/}


      {/*<Button 
        className="w-full bg-gradient-to-r from-[#13294B] to-[#E84A27] text-white hover:from-[#E84A27] hover:to-[#13294B] transition-all duration-300"
      >
        <Users className="mr-2 h-4 w-4" />
        {"Join the Illini Community"}
      </Button>*/}