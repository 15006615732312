import { useRef, useEffect } from 'react';

const TOP_PROMPTS = [
  'who\'s making short form content?',
  'someone to take my graduation pics',
  'show me hardware',
  'who can help me design a logo?',
  'i need to hire an app developer',
  'looking for AI researchers',
  'who wants to start a band?',
];
const BOTTOM_PROMPTS = [
  'advice for rushing a business frat',
  'who\'s looking for study partners for CS 225?',
  'i need a ride to chicago this weekend',
  'looking for roommates for next semester',
  'anyone doing undergrad research in psychology?',
  'who’s into filmmaking?',
  'i need a haircut',
];

export default function PromptCarousel({ reverse, onPromptClick }) {
  const promptCarouselRef = useRef(null);
  const prompts = reverse ? TOP_PROMPTS : BOTTOM_PROMPTS;

  useEffect(() => {
    const promptScroll = promptCarouselRef.current;
    let animationId;

    const scrollPrompt = () => {
      if (reverse ? promptScroll.scrollLeft <= 0 : promptScroll.scrollLeft >= promptScroll.scrollWidth / 2) {
        promptScroll.scrollLeft = reverse ? promptScroll.scrollWidth / 2 : 0;
      } else {
        promptScroll.scrollLeft += reverse ? -0.7 : 1.2;
      }
      animationId = requestAnimationFrame(scrollPrompt);
    };

    animationId = requestAnimationFrame(scrollPrompt);

    return () => cancelAnimationFrame(animationId);
  }, [reverse]);

  return (
    <div
      ref={promptCarouselRef}
      className="flex overflow-x-hidden space-x-4 pb-4 mb-8"
      aria-label="Search prompts carousel"
    >
      {[...Array(2)].map((_, i) => (
        <div key={i} className="flex space-x-4 opacity-50">
          {prompts.map((query) => (
            <button
              key={`${query}-${i}`}
              className="p-2 border-2 border-gray-400 bg-[#0A0A0A] text-white hover:bg-[#BB5E0E] whitespace-nowrap"
              onClick={() => onPromptClick && onPromptClick(query)}
            >
              <span className="text-gray-400">{query}</span>
            </button>
          ))}
        </div>
      ))}
    </div>
  );
}
