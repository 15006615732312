import React from 'react'
import { useState } from 'react'
import { motion } from "framer-motion"
import { Linkedin, Phone, Mail, Instagram, Twitter, Send, Globe } from 'lucide-react'
import { Avatar, AvatarFallback, AvatarImage } from "@/components/ui/avatar"
import { Card, CardContent } from "@/components/ui/card"
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog"
import { Button } from "@/components/ui/button"

interface ContactMethods {
  phone?: string;
  email?: string;
  instagram?: string;
  twitter?: string;
  telegram?: string;
  website?: string;
}

interface CreatorCardProps extends ContactMethods {
  name?: string;
  description: string;
  avatarSrc: string;
}

export function CreatorCard({ 
  name = 'Unknown',
  description,
  avatarSrc,
  phone,
  schoolEmail,
  personalEmail,
  instagram,
  twitter,
  telegram,
  website,
  linkedin,
}: CreatorCardProps) {
  const [open, setOpen] = useState(false)

  const contactMethods = [
    { icon: Phone, value: phone, href: `tel:${phone}`, label: 'Phone' },
    { icon: Mail, value: schoolEmail, href: `mailto:${schoolEmail}`, label: 'Email' },
    { icon: Mail, value: personalEmail, href: `mailto:${personalEmail}`, label: 'Email' },
    { icon: Instagram, value: instagram, href: `https://instagram.com/${instagram}`, label: 'Instagram' },
    { icon: Twitter, value: twitter, href: `https://twitter.com/${twitter}`, label: 'Twitter' },
    { icon: Send, value: telegram, href: `https://t.me/${telegram}`, label: 'Telegram' },
    { icon: Globe, value: website, href: website, label: 'Website' },
    { icon: Linkedin, value: linkedin, href: `https://www.linkedin.com/in/${linkedin}`, label: 'Linkedin' },
  ].filter(method => method.value)

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          transition={{ duration: 0.3 }}
        >
          <Card className="mt-2 bg-black border-gray-800 shadow-lg hover:bg-zinc-900 transition-colors cursor-pointer">
            <CardContent className="p-4">
              <div className="flex items-center space-x-4">
                <Avatar>
                  <AvatarImage src={avatarSrc} alt={name} />
                  <AvatarFallback>{name[0].toUpperCase()}</AvatarFallback>
                </Avatar>
                <div>
                  <h3 className="font-semibold text-[#CFD2D8]">{name}</h3>
                  <p className="text-sm text-gray-400">{description}</p>
                </div>
              </div>
            </CardContent>
          </Card>
        </motion.div>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px] bg-zinc-900 text-zinc-100 border-zinc-800">
        <DialogHeader>
          <DialogTitle className="text-2xl font-bold mb-4">contact {name}</DialogTitle>
        </DialogHeader>
        <div className="grid gap-4">
          {contactMethods.map((method, index) => (
            <a 
              key={index}
              href={method.href} 
              target={method.icon !== Phone && method.icon !== Mail ? "_blank" : undefined}
              rel="noopener noreferrer"
              className="flex items-center gap-3 p-2 rounded-md hover:bg-zinc-800 transition-colors"
            >
              {React.createElement(method.icon, { className: "h-5 w-5 text-zinc-400" })}
              <span>{method.value}</span>
            </a>
          ))}
        </div>
      </DialogContent>
    </Dialog>
  )
}